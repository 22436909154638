import { AecomCoreModule, LayoutService } from '@aecom/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  Configuration,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { NgxSpinnerModule } from 'ngx-spinner';
import environment from 'src/environments/environment';
import { OAuthSettings } from '../oauth';
import { BASE_PATH } from './api-generated/variables';
import AppRoutingModule from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog'; // Import MatDialogModule
import AppComponent from './app.component';
import AccessDeniedComponent from './content/auth/access-denied/access-denied.component';
import AuthInterceptor from './content/auth/auth.interceptor';
import AuthService from './content/auth/auth.service';
import DataPrivacyComponent from './content/data-privacy/data-privacy.component';
import ErrorComponent from './content/error/error.component';
import NavigationComponent from './content/navigation/navigation.component';
import PortalComponent from './content/portal/portal.component';
import SingoutComponent from './content/singout/singout.component';
import ErrorResolve from './resolvers/error-resolver';
import LoginUserResolve from './resolvers/login-user-resolver';
import LocalStorageService from './services/local-storage.service';
import SidenavService from './services/nav.service';
import { StorageKey } from './shared/localstorage';
import LocalTaskService from './services/local-task.service';
import { CalendarComponent } from './content/calendar/calendar.component';
import { CalendarPageComponent } from './content/calendar-page/calendar-page.component';
import { EventDetailDialogComponent } from './content/event-detail-dialog/event-detail-dialog.component';
import { BBJCoreModule } from '@bbj/components';

export function tokenGetter(): string {
  return localStorage.getItem(StorageKey.TOKEN);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
    },
    cache: {
      cacheLocation: 'localStorage',
      // set to true for IE 11
      storeAuthStateInCookie: false,
    },
  });
}

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
      // set to true for IE 11
      storeAuthStateInCookie: false,
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: OAuthSettings.protectedResourceMap,
  };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: OAuthSettings.scopes,
    },
    loginFailedRoute: environment.url.redirectUri,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    NavigationComponent,
    PortalComponent,
    DataPrivacyComponent,
    ErrorComponent,
    SingoutComponent,
    CalendarComponent,
    CalendarPageComponent,
    EventDetailDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory(),
    ),
    BrowserAnimationsModule,
    DragDropModule,
    AecomCoreModule,
    BBJCoreModule,
    MatDialogModule,
  ],
  providers: [
    DatePipe,
    LocalStorageService,
    AuthService,
    SidenavService,
    LayoutService,
    ErrorResolve,
    LoginUserResolve,
    LocalTaskService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: BASE_PATH, useValue: environment.baseWebAPI },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
